<template>
  <popup-modal ref="popup">
    <a href="javascript:void(0)" class="close-modal" @click="closeModal">
      <img :src="closeImg" alt="Close" />
    </a>
    <h2>{{ title }}</h2>
    <div v-html="message"></div>
    <div class="btns">
      <button class="ok-btn" @click="_confirm">{{ okButton }}</button>
    </div>
  </popup-modal>
</template>

<script>
import PopupModal from "./ConfirmModal.vue";
export default {
  name: "AlertModal",
  components: { PopupModal },
  data: () => ({
    closeImg: "/media/close_btn.png",
    // Parameters that change depending on the type of dialogue
    title: undefined,
    message: undefined, // Main text content
    okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for

    // Private variables
    resolvePromise: undefined,
    rejectPromise: undefined,
  }),
  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      // Once we set our config, we tell the popup modal to open
      this.$refs.popup.open();
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    _confirm() {
      this.$refs.popup.close();
      this.resolvePromise(true);
    },
    closeModal() {
      this.$refs.popup.close();
    },
  },
};
</script>

<style scoped>
h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: bold;
}

.btns {
  display: inline-flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 20px;
}

.ok-btn {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  /*background: linear-gradient(180deg, #CBCBCB 0%, #B3B3B3 100%);*/
  background: linear-gradient(180deg, #45bcff 0%, #00a3ff 100%);
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.07),
    inset 0 2px 0 rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 30px;
  padding: 6px 20px;
  font-size: 16px;
}

.cancel-btn {
  text-decoration: none;
  padding: 6px 20px;
  color: #fff;
  border: none;
  font-weight: bold;
  font-size: 16px;
  cursor: pointer;
  background: linear-gradient(180deg, #45bcff 0%, #00a3ff 100%);
  box-shadow: 4px 4px 0 rgba(0, 0, 0, 0.07),
    inset 0 2px 0 rgba(255, 255, 255, 0.2);
  border-radius: 30px;
  margin-right: 15px;
}

.close-modal {
  position: absolute;
  right: 9px;
  top: 8px;
}

.close-modal img {
  max-width: 100%;
}
</style>
